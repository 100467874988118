import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import styled from '@emotion/styled'
import Layout from '../components/layout'

const PostContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-block-end: 0;
  :hover {
    text-decoration: underline;
    color: #20201c;
  }
`

const StyledDate = styled.p`
  display: block;
  margin-block-start: 0;
`

const ArticleTitle = styled.h1`
  margin-block: 0;
  margin-block: 0;
  color: #20201c;
  font-size: 3rem;
  line-height: 1.25;
`
function BlogPostTemplate(props) {
  const post = get(props, 'data.contentfulBlogPost')
  const siteTitle = get(props, 'data.site.siteMetadata.title')

  return (
    <Layout location={props.location}>
      <PostContainer style={{ background: 'none' }}>
        <Helmet htmlAttributes={{ lang: 'en' }} title={`${post.title} | ${siteTitle}`} />
        <div className="wrapper">
          <StyledLink to="/">&#60; More writing</StyledLink>
          <ArticleTitle className="section-headline">{post.title}</ArticleTitle>
          <StyledDate
          >
            {post.publishDate}
          </StyledDate>
          <div
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
          <StyledLink to="/"
            style={{
              display: 'block',
              marginTop: '3rem'
            }}
          >
            &#60; More writing
          </StyledLink>
        </div>
      </PostContainer>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
